import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, ReactNode } from 'react';
import {
  StructuredText as DefaultStructuredText,
  renderNodeRule,
} from 'react-datocms';
import Link from '@/components/Link';
import { isHeading, isListItem, isList } from 'datocms-structured-text-utils';
import { Card, PersonCard, Fact, Jumbotron } from '@mikafi/ui';
import { ElementType } from 'react';
import { graphql, Script, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from 'gatsby-plugin-image/dist/src/components/gatsby-image.server';

interface Props {
  record: any;
}

const config = {
  customNodeRules: [
    renderNodeRule(isHeading, ({ node, children, key }) => {
      const level = node.level === 1 ? 2 : node.level;
      const HeadingTag: ElementType = `h${level}`;

      return (
        <HeadingTag key={key} className="mb-4">
          {children}
        </HeadingTag>
      );
    }),
  ],
};

interface IConditionalWrapProps {
  condition: boolean;
  wrap: Function;
  children: ReactNode;
}

const ConditionalWrap: FC<IConditionalWrapProps> = ({
  condition,
  wrap,
  children,
}) => (condition ? wrap(children) : children);

const Content: FC<Props> = ({ record }) => {
  const intl = useIntl();

  switch (record.__typename) {
    case 'DatoCmsSectionJumbotron':
      return (
        <div className="mb-24 md:mb-32">
          <Jumbotron
            title={record.title}
            subtitle={record.subtitle}
            content={
              record.content?.value && (
                <DefaultStructuredText
                  {...config}
                  data={record.content}
                  renderBlock={({ record }: { record: any }) => {
                    switch (record.__typename) {
                      case 'DatoCmsBlockLink':
                        return (
                          <Link
                            to={`/${record.internalLink.slug}/`}
                            className="mt-4 mr-4 inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                          >
                            {record.title}
                          </Link>
                        );
                      case 'DatoCmsBlockExternalLink':
                        return (
                          <div className="my-4">
                            <a
                              target="_blank"
                              rel="noopener"
                              href={record.url}
                              className="inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                            >
                              {record.title}
                            </a>
                          </div>
                        );
                      default:
                        return null;
                    }
                  }}
                />
              )
            }
          />
        </div>
      );
    case 'DatoCmsSectionText':
      return (
        <div
          className={classNames('mb-32', {
            'md:flex': record.titlePosition === 'left',
          })}
        >
          {!!record.title && (
            <div
              className={classNames('mb-6', {
                'flex-none md:w-3/12 md:mr-16': record.titlePosition === 'left',
              })}
            >
              <h3 className="pt-3">{record.title}</h3>
            </div>
          )}

          <div>
            <DefaultStructuredText
              {...config}
              data={record.content}
              renderBlock={({ record }: { record: any }) => {
                switch (record.__typename) {
                  case 'DatoCmsBlockImageGrid':
                    return (
                      <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
                        {record.images.map((item: any) => (
                          <ConditionalWrap
                            key={item.id}
                            condition={item.link}
                            wrap={(children: any) => (
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener"
                              >
                                {children}
                              </a>
                            )}
                          >
                            <div className="aspect-video">
                              <GatsbyImage
                                className="h-full w-full"
                                objectFit="contain"
                                image={item.image?.gatsbyImageData}
                                alt={item.image?.title || ''}
                              />
                            </div>
                          </ConditionalWrap>
                        ))}
                      </div>
                    );
                  case 'DatoCmsBlockTeam':
                    const staticData = useStaticQuery(graphql`
                      {
                        allDatoCmsPerson(
                          sort: { fields: position, order: ASC }
                        ) {
                          nodes {
                            id: originalId
                            locale
                            name
                            description
                            title
                            personType
                            image {
                              title
                              gatsbyImageData
                            }
                          }
                        }
                      }
                    `);

                    return (
                      <div className="lg:h-[32rem]">
                        <div className="-mx-6 md:-mx-8 lg:m-0 lg:left-0  lg:absolute lg:w-screen ">
                          <div className="flex overflow-x-scroll snap-mandatory snap-x -mx-2 ">
                            {staticData.allDatoCmsPerson.nodes
                              .filter(
                                (person: any) => person.locale === intl.locale,
                              )
                              .filter(
                                (person: any) =>
                                  person.personType === record.teamType,
                              )
                              .map((person: any) => (
                                <div
                                  key={person.id}
                                  className="snap-center mx-2 w-10/12 sm:w-5/12 md:w-3/12 lg:w-1/4 flex-none first:ml-6 md:first:ml-8 lg:first:ml-16 last:mr-6 md:last:mr-8 lg:last:mr-16"
                                >
                                  <PersonCard
                                    key={person.id}
                                    name={person.name}
                                    title={person.title}
                                    description={person.description}
                                    image={
                                      <GatsbyImage
                                        className="h-full w-full"
                                        objectFit="cover"
                                        image={person.image?.gatsbyImageData}
                                        alt={person.image?.title || ''}
                                      />
                                    }
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    );
                  case 'DatoCmsBlockCardGrid':
                    return (
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {record.cards.map((card: any) => (
                          <Card
                            key={card.id}
                            title={card.title}
                            subtitle={card.subtitle}
                            additionalInformation={card.additionalInformation}
                            image={
                              <GatsbyImage
                                className="h-full w-full"
                                objectFit="cover"
                                image={card.image?.gatsbyImageData}
                                alt={card.image?.title || ''}
                              />
                            }
                            linkComponent={props => (
                              <Link
                                {...props}
                                to={card.link ? `/${card.link}/` : '/'}
                              />
                            )}
                            linkName={card.linkName}
                            showButton={card.showLinkButton}
                          />
                        ))}
                      </div>
                    );
                  case 'DatoCmsBlockFactGrid':
                    return (
                      <div className="grid md:grid-cols-2 gap-12">
                        {record.facts.map((fact: any) => (
                          <Fact
                            key={fact.id}
                            title={fact.title}
                            subtitle={fact.subtitle}
                            text={fact.text}
                          />
                        ))}
                      </div>
                    );
                  case 'DatoCmsBlockLink':
                    return (
                      <div className="my-4">
                        <Link
                          to={`/${record.internalLink.slug}/`}
                          className="inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                        >
                          {record.title}
                        </Link>
                      </div>
                    );
                  case 'DatoCmsBlockExternalLink':
                    return (
                      <div className="my-4">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={record.url}
                          className="inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                        >
                          {record.title}
                        </a>
                      </div>
                    );
                  case 'DatoCmsBlockList': {
                    return (
                      <DefaultStructuredText
                        data={record.text}
                        customNodeRules={[
                          renderNodeRule(isListItem, ({ children, key }) => {
                            return (
                              <li
                                key={key}
                                className="text-4xl font-medium list-none"
                              >
                                {children}
                              </li>
                            );
                          }),
                          renderNodeRule(isList, ({ children, key }) => {
                            return (
                              <ul key={key} className="p-0">
                                {children}
                              </ul>
                            );
                          }),
                        ]}
                      />
                    );
                  }
                  case 'DatoCmsBlockVideo': {
                    return (
                      <>
                        <div
                          style={{
                            padding: '56.25% 0 0 0',
                            position: 'relative',
                          }}
                          className="rounded-3xl overflow-hidden"
                        >
                          <iframe
                            src={`https://player.vimeo.com/video/${record.video.providerUid}`}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            className="rounded-3xl overflow-hidden"
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                            }}
                            title={record.title}
                          />
                        </div>
                        <Script src="https://player.vimeo.com/api/player.js"></Script>
                      </>
                    );
                  }
                  default:
                    return null;
                }
              }}
            />
          </div>
        </div>
      );
    case 'DatoCmsSectionTextImage':
      return (
        <div className="md:-mx-16 md:flex md:flex-wrap mb-24">
          <div className="md:flex-1 md:mx-16">
            <div className="mb-3">
              <h3 className="text-lg tracking-wider">{record.title}</h3>
            </div>
            <DefaultStructuredText
              {...config}
              data={record.content}
              renderBlock={({ record }: { record: any }) => {
                switch (record.__typename) {
                  case 'DatoCmsBlockLink':
                    return (
                      <div className="my-4">
                        <Link
                          to={`/${record.internalLink.slug}/`}
                          className="inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                        >
                          {record.title}
                        </Link>
                      </div>
                    );
                  case 'DatoCmsBlockExternalLink':
                    return (
                      <div className="my-4">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={record.url}
                          className="inline-block bg-gray-100 py-4 px-8 no-underline rounded-full font-medium text-xs hover:bg-gray-400/20 hover:text-gray-900"
                        >
                          {record.title}
                        </a>
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>
          <div className="md:flex-1 md:mx-16 rounded-4xl overflow-hidden">
            <GatsbyImage
              alt={record.image?.title || ''}
              image={record.image?.gatsbyImageData}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Content;
