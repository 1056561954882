import React from 'react';
import Layout from '@/components/Layout';
import { graphql, PageProps } from 'gatsby';
import Seo from '@/components/Seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import StructuredText from '@/components/Content';
import { Header, ImageHeader } from '@mikafi/ui';
import Link from '@/components/Link';

type PageContextProps = {
  id: string;
};

type DataProps = {
  datoCmsPage: any;
};

const Page = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const page = data.datoCmsPage;
  const hasImage = !!page.image?.gatsbyImageData;
  const imageVariant = page.imageVariant || 'background';
  const slugs = page.slugLocales;

  return (
    <Layout
      variant={hasImage || page.introText ? 'light' : 'dark'}
      navChangeColor={hasImage ? true : false}
      alternatePages={slugs}
    >
      <Seo
        title={page.seo?.title || page.title}
        description={page.seo?.description}
      />

      <div
        className={classNames('bg-gray-200', {
          'pt-24': !hasImage && !page.introText,
        })}
      >
        {hasImage ? (
          <ImageHeader
            imageVariant={imageVariant}
            image={
              !!page.image && (
                <GatsbyImage
                  className={classNames({
                    'aspect-video min-h-[60vh] lg:max-h-[80vh] lg:aspect-auto':
                      imageVariant === 'background',
                    'aspect-square': imageVariant === 'arranged',
                  })}
                  alt={page.title}
                  image={!!page.image && page.image?.gatsbyImageData}
                  objectFit="cover"
                />
              )
            }
            title={page.title}
            subtitle={page.subtitle}
            cta={
              page.ctaLink &&
              page.ctaName && (
                <Link
                  to={page.ctaLink}
                  className="no-underline tracking-wide rounded-full bg-none border-white border text-white py-4 px-6 font-normal text-sm sm:text-base inline-block mt-8"
                >
                  {page.ctaName}
                </Link>
              )
            }
          />
        ) : page.introText ? (
          <Header
            cta={
              <Link
                to={page.ctaLink}
                className="no-underline tracking-wide rounded-full bg-none border-white border text-white py-4 px-6 font-normal text-sm sm:text-base inline-block mt-8"
              >
                {page.ctaName}
              </Link>
            }
            title={page.title}
            subtitle={page.subtitle}
            content={page.introText}
          />
        ) : (
          <div
            className={
              'mx-6 md:mx-4 lg:mx-16 max-w-screen-xl 2xl:mx-auto 2xl:px-16'
            }
          >
            <h1>{page.title}</h1>
            <h3>{page.subtitle}</h3>
          </div>
        )}
      </div>

      <div className="bg-gray-200 py-20">
        <div
          className={classNames(
            'mx-6 md:mx-8 lg:mx-16 max-w-screen-xl xl:mx-auto 2xl:px-16',
          )}
        >
          {page.content?.map((record: any) => (
            <StructuredText key={record.id} record={record} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($id: String) {
    datoCmsPage(id: { eq: $id }) {
      ...Page
    }
  }
`;
